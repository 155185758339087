import * as ss58 from '@chainflip/utils/ss58';
import { isHex } from '@chainflip/utils/string';
import { type HexString } from '@chainflip/utils/types';
import { type SubmittableExtrinsic } from '@polkadot/api/types';
import { z } from 'zod';
import { type ChainflipToken } from '@/shared/assets/tokens';
import { POLKADOT_PREFIX } from '@/shared/constants';
import { chainflipAssetMap, parseIntOrThrow, hexToUtf8, toCamelCase } from '@/shared/utils';
import { addressTypes, chainflipAssets } from '@/shared/utils/chainflip';

export const hexString = z
  .string()
  .refine((str): str is `0x${string}` => /^0x[0-9a-f]*$/i.test(str));

type EventRecord = Parameters<
  NonNullable<Parameters<SubmittableExtrinsic<'promise'>['signAndSend']>[2]>
>[0]['events'][number];

const addressDecodeMap: Record<
  ChainflipToken['chain']['chainflipId'],
  (addr: HexString) => string
> = {
  Bitcoin: hexToUtf8,
  Ethereum: (str) => str,
  Polkadot: (str) => ss58.encode({ data: str, ss58Format: POLKADOT_PREFIX }),
  Arbitrum: (str) => str,
};

const caseInsensitiveAddressType = z
  .string()
  .transform((asset) => toCamelCase(asset))
  .pipe(z.enum(addressTypes));

const parsers = {
  'liquidityProvider.LiquidityDepositAddressReady': z
    .object({
      depositChainExpiryBlock: z.union([z.number(), hexString]).transform(parseIntOrThrow),
      depositAddress: z
        .record(caseInsensitiveAddressType, z.string())
        .transform((obj) => Object.values(obj)[0])
        .refine(isHex),
      asset: z.enum(chainflipAssets).transform((asset) => chainflipAssetMap[asset]),
      channelId: z.number(),
      boostFee: z.number(),
    })
    .transform(({ asset, depositAddress, ...rest }) => {
      const address = addressDecodeMap[asset.chain.chainflipId](depositAddress);
      return { asset, depositAddress: address, ...rest };
    }),
} as const;

export type LiquidityDepositAddressReady = z.output<
  (typeof parsers)['liquidityProvider.LiquidityDepositAddressReady']
>;

export const getEventArgs = <P extends keyof typeof parsers>(name: P, records: EventRecord[]) => {
  const record = records.find((r) => `${r.event.section}.${r.event.method}` === name);

  if (!record) return null;

  const { data } = record.event;
  const { names } = data;

  if (!names) return null;

  const parser = parsers[name];

  return parser.parse(Object.fromEntries(names.map((n, index) => [n, data[index].toJSON()])));
};
